.news-detail-main{
  .news-detail{
    padding: 30px 15px;
    .news-detail-title{
      width: 255px;
      font-size: 15px;
      font-weight: 400;
      text-align: center;
      color: #414141;
      line-height: 20pt;
      margin-left: 45px;
    }
    .news-detail-date{
      width: 100%;
      font-size: 10px;
      font-weight: 400;
      text-align: center;
      color: #6d6d6d;
      line-height: 20px;
      margin-top: 18px;
      display: flex;
      justify-content: center;
    }
    .news-line{
      margin-top: 10px;
      border-bottom: 1px solid #EAEAEA;
    }
    .news-detail-content{
      padding: 18px 0px;
      min-height: 250px;
      font-size: 13px;
      font-weight: 400;
      text-align: left;
      color: #414141;
      line-height: 20px;
      p{
        text-indent: 13px;
      }
    }
  }
}
