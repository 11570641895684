.index-main {
  .swiper-container {
    width: 100%;
    height: 200px;
    margin-top: 50px;
  }
  .swiper-pagination-bullet {
    width: 40px;
    height: 3px;
    border-radius: 1px;
    background: #B6B1DF;
  }
  .swiper-pagination-bullet-active {
    background: #FFFFFF;
  }

  .index-txpp-product-price{
    width: 100%;
    background: #ffffff;
    .index-txpr-title {
      background: #2645C0;
      img{
        width: 112px;
        margin-left: 116.5px;
        height: 16px;
      }
    }
    .index-txpr-table{
      width: 100%;
      .index-txpr-header{
        background: #2645C0;
        .index-header-th{
        }
      }
      .index-txpr-content{
        height: 75px;
        padding-top: 12px;
        .index-header-th{

          p{
            line-height: 18px;
          }
        }
      }
      .box-position{
        position: relative;
        .service-text{
          position: absolute;
          top:40px;
          left:26px;
          color: #2C4AC2;
          font-size: 16px;
          z-index: 9;
        }
      }
    }
  }
  .index-txep-product-price{
    .index-txep-title{
      background: #309F62;
      img{
        width: 127px;
        margin-left: 101.5px;
        height: 16px;
      }
    }
    .index-txep-table{
      width: 100%;
      .index-txep-header{
        background: #309F62;
        .index-header-th{
        }
      }
      .index-txep-content{
        height: 55px;
        line-height: 55px;
        .index-header-th{
          p{
            margin-top: 15px;
          }
        }
      }
    }
    .index-table-btn{
      background: linear-gradient(90deg,#309f62, #46c880);
      box-shadow: 1px 2px 0px #219454;
    }
  }
  .index-txcs-product-price{
    .index-txep-title{
      background: #DD8436;
      img{
        width: 159px;
        margin-left: 85.5px;
        height: 16px;
      }
    }
    .index-txep-table{
      width: 100%;
      .index-txep-header{
        background: #DD8436;
        .index-header-th{
        }
      }
      .index-txep-content{
        height: 55px;
        line-height: 55px;
        .index-header-th{
          p{
            margin-top: 15px;
          }
        }
        .aa{
          flex:2
        }
      }
    }
    .index-table-btn{
      background: linear-gradient(90deg,#dd8436, #f89c4c);
      box-shadow: 1pt 2pt 0pt 0pt #d37828;
    }
  }
  .index-txqq-product-price{
    .index-txep-title{
      background: #3AADD8;
      img{
        width: 159px;
        margin-left: 85.5px;
        height: 16px;
      }
    }
    .index-txep-table{
      width: 100%;
      .index-txep-header{
        background: #3AADD8;
        .index-header-th{
        }
      }
      .index-txep-content{
        height: 55px;
        line-height: 55px;
        .index-header-th{
          p{
            margin-top: 15px;
          }
        }
        .aa{
          flex:2
        }
      }
    }
    .index-table-btn{
      background: linear-gradient(90deg,#25a1cf, #47bde9);
      box-shadow: 1pt 2pt 0pt 0pt #1988b2;
    }
  }
  .index-table-title {
    padding:15px 22px;
  }
  .index-table-table{
    width: 100%;
    font-size: 13px;
    .index-table-header{
      width: 100%;
      padding: 0 15px;
      height: 40px;
      line-height: 40px;
      display: flex;
      text-align: center;
      .index-header-th{
        flex: 1;
        color: #ffffff;
        text-align: center;
      }
    }
    .index-table-content{
      padding: 0 15px;
      display: flex;
      text-align: center;
      border-bottom: 1px solid #EAECF4;
      .index-header-th{
        flex: 1;
        color: #3F3F3F;
        text-align: center;
        p{
          line-height: 16px;
        }
      }
    }
  }
  .index-table-footer{
    color: #FF6609;
    font-size: 13px;
    padding: 20px 0 14px 0;
    text-align: center;
  }
  .index-table-btn{
    width: 345px;
    margin: 0 15px 25px;
    height: 40px;
    line-height: 40px;
    color: #ffffff;
    background: linear-gradient(90deg,#2645c0, #4367f4);
    box-shadow: 1px 2px 0px #1f389A;
    font-size: 16px;
  }
  .index-footer{
    width: 100%;
    background: #141F32;
    padding: 22px 15px;
    .footer-news{
      font-size: 13px;
      color: #ffffff;
      margin-bottom: 22px;
      .footer-news-title{
        height: 30px;
        p:nth-child(1){
          width: 100%;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
        }
        p:nth-child(2){
          width: 33px;
          height: 2px;
          background: #ffffff;
          margin: 13px 156px 0;
        }
      }
      .footer-news-content{
        ul{
          margin-top: 23px;
          list-style: disc;
          padding-bottom: 12px;
          border-bottom: 1px solid #192437;
          li{
            margin-bottom: 20px;
            div{
              display: flex;
              justify-content: space-between;
              .news-disc{
                width: 4px;
                height: 4px;
                border-radius: 50%;
              }
              p{
                width: 210px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              span{
                color:#565F6E;
              }
            }
          }
        }
      }
    }
    .foot-qr-code{
      width: 100%;
      height: 98px;
      text-align: center;
      .foot-qr-code-img{
         width: 74px;
         height: 74px;
         margin: 0 135px;
      }
      .foot-qr-code-text{
        margin-top: 13px;
        font-size: 11px;
        color: #ffffff;
      }
    }
  }
  .index-footer-company{
    font-size: 13px;
    color: #F8F8F8;
    text-align: center;
    width: 100%;
    line-height: 15px;
    padding: 15px 15px;
    background: #101B2E;
  }
  .index-footer-btn{
    font-size: 14px;
    width: 100%;
    height: 45px;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    .online{
      height: 45px;
      width: 138px;
      background: #f5f9ff;
      color: #3B63AB;
      i{
        font-size: 16px;
        margin-right: 5px;
      }
    }
    .telephone{
      font-size: 14px;
      font-weight: bold;
      font-weight: 400;
      color: #ffffff;
      background: #3B63AB;
      width: 237px;
      height: 45px;
    }

  }
}
