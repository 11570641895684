.rm-main{
  .rm-main-box{
    background: #090531;
    padding: 0 15px 43px;
    .rm-main-content{
      padding: 25px 15px 9px;
      background: #101141;
      .rm-main-content-title{
        width: 242px;
        height: 18px;
        margin-left: 36.5px;
      }
      .title2{
        width: 211px;
        height: 18px;
        margin-left: 52px;
      }
      .rm-main-content-text{
        margin-top: 8px;
        font-size: 14px;
        line-height: 26px;
        padding: 16px 0;
        width: 100%;
        color: #EAEAEA;
        p{
          text-align: left;
          letter-spacing: 2px;
          width: 285px;
          margin: 20px 20px 0;
          font-size: 14px;
          span{
            color: #B04018;
          }
        }
        p:nth-child(1){
          margin-top: 0px;
        }
      }
      .rm-main-content-rmark{
        padding: 13px 10px;
        text-align: center;
        color: #EAEAEA;
        font-size: 14px;
        background: #090531;
        letter-spacing: 2px;
        margin-bottom: 24px;
        p{
          margin-top: 11px;
        }
        p:nth-child(1){
          margin-top: 0;
        }
      }
    }
  }
}
