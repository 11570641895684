* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  box-sizing: border-box;
  vertical-align: baseline;
}

:root {
  overflow-y: auto;
  overflow-x: hidden;
}
html, body {
  -webkit-overflow-scrolling: touch;
}
html {
  overflow-y: scroll;
  touch-action: manipulation;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  width: 100vw;
  line-height: 1;
  // overflow: hidden; // 影响sticky定位
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
ul, ol, li {
  list-style: none;
}
textarea {
  -webkit-appearance: none;
}
a {
  -webkit-backface-visibility: hidden;
}
img {
  -webkit-touch-callout: none;
  // pointer-events: none; // 会阻止图片的点击事情
  user-select: none;
}
img[src=""],
img:not([src]) {
  opacity: 0; // 避免图片加载之前出现边框
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
