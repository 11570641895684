.coupon-main{
  background: #ffffff;
  .header-title{
    padding: 25px 15px;
    background: #05022E;
    margin-top: 50px;
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    p{
      line-height: 30px;
    }
    p:nth-child(2){
      margin-top: 6px;
    }
  }
  .link-img{
    width: 100%;
    height: 180px;
  }
  .coupon-model-content{
    padding: 25px 15px;
    background: #05022E;
    .index-table-btn{
      background: linear-gradient(90deg,#b07a34, #cda84d);
      box-shadow: 1pt 2pt 0pt 0pt #a4630c;
      color: #ffffff;
      font-size: 15px;
      padding: 14px;
      width: 100%;
    }
    p{
      margin-top: 20px;
      margin-bottom: 9px;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      width: 100%;
      font-size: 15px;
    }
  }

}

