.about-main{
  .about-main-info{
    width: 100%;
    padding: 25px 15px;
    background: #ffffff;
  }
  .about-main-logo{
    width: 115px;
    height: 44px;
    margin: 27px 115px 4px 115px;
  }
  .about-main-content{
    p{
      text-indent: 20px;
      font-size: 13px;
      font-weight: 400;
      text-align: left;
      color: #737373;
      line-height: 18px;
      margin-top: 15px;
    }
  }
  .about-main-natural{
    width: 100%;
    padding: 25px 15px;
    background: #fafafa;
  }
}
