.contact-main{
  .contact-main-info{
    width: 100%;
    padding: 25px 15px;
    background: #ffffff;
  }
  .contact-main-content{
    width: 100%;
    padding: 32px 0 6px;
    .content-title{
      font-weight: 700;
      text-align: left;
      color: #303030;
      font-size: 14px;
      letter-spacing: 1px;
    }
    .content-text{
      margin-top: 15px;
      p{
        font-weight: 400;
        text-align: left;
        color: #737373;
        line-height: 23pt;
        font-size: 13px;
        letter-spacing: 1px;
      }
    }
  }
  .contact-main-form{
    background: #fafafa;
    width: 100%;
    padding: 25px 15px;
  }
}
input::-webkit-input-placeholder,textarea::-webkit-input-placeholder{
　color:#C3C3C3;
　font-size:12px;
}
