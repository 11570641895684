.news-main{
  .news-list {
    padding: 10px 15px;
    ul{
      width: 100%;
      li{
        padding: 0 15px;
        border-bottom: 1px solid #EAEAEA;
        line-height: 45px;
        a{
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        .news-title{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 250px;
          color: #414141;
          font-size: 14px;
        }
        .news-date{
          color: #8F8F8F;
        }
      }
    }
    .news-page-btn{
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 25px 0;
      button{
        width: 162px;
        height: 40px;
        background: #E6F3FF;
        color: #141F32;
      }
    }
  }
}
