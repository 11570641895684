@import 'base/normalize';
@import 'base/reset';
@import 'base/mixin';
@import 'css/index';
@import 'css/about';
@import 'css/contact';
@import 'css/recommend';
@import 'css/coupon';
@import 'css/txemail';
@import 'css/news';
@import 'css/newsDetail';

.noplay{
  display: none;
}
*{
  margin: 0;
  padding: 0;
  border:0;
}
a{
  color: #ffffff;
  text-decoration:none;
}
img{
  width: 100%;
  height: 100%;
}
.top-bar {
   height: 50px;
   width: 100%;
   position: fixed;
   left: 0px;
   top: 0px;
   z-index: 999;
   background: #FFFFFF;
}
.top-bar .top-cotent{
  height: 30px;
  width: 345px;
  position: relative;
  top:14px;
  left:15px;
  display: flex;
  align-items: center;
}
.top-left-logo{
  width: 84px;
  height: 30px;
}
.top-right-logo{
   width: 130px;
   height: 20px;
  margin-left: 7px;
  padding-left: 7px;
  border-left: 1px solid #E8E8E8;
  box-sizing: border-box;
 }
.top-bg{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0,0,0,0.5);
}
.top-right-logo img{
  width: 122px;
  height: 20px;
}
.top-icon-more{
  font-size: 30px;
  position: absolute;
  bottom:-5px;
  right: 0px;
}
.noBot{
  margin-bottom: 0 !important;
}
.top-navi-right{
  width: 211px;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index:9999;
  background: #ffffff;
  animate-duration: 2s;    //动画持续时间
  animate-delay: 0s;    //动画延迟时间
  animate-iteration-count: 1;    //动画执行次数
  .top-close-box{
    width: 100%;
    height: 50px;
    position: relative;
    span{
      font-size: 16px;
      position: absolute;
      top:18px;
      right: 17px;
    }
  }
  .top-menu-context{
    width: 100%;
    padding: 16px 0;
    .menu-item{
      width: 100%;
      display: flex;
      align-items: center;
      padding: 16px 0px 16px 52px;
      span {
        font-size: 15px;
        margin-right: 8px;
        color: #7c808c;
      }
      p{
        width: 138px;
        font-size: 14px;
        color: #3F3F3F;
      }
    }
  }
  .top-menu-btn{
    width: calc(100% - 36px);
    height: 35px;
    background: #3d62f3;
    border-radius: 1px;
    margin: 14px 18px;
    color: #FFFFFF;
  }
  .top-menu-call{
    display: flex;
    align-items: center;
    width: calc(100% - 56px);
    margin-left: 56px;
    margin-top: 9px;
    color: #3F3F3F;
    .iconfont{
      margin-right: 6px;
      font-size: 18px;
    }
    .top-menu-call-span{
      font-size: 12px;
    }
  }
  .top-click-tips{
    color: #898D97;
    font-size: 10px;
    text-align: center;
    width: 100%;
    margin-top: 5px;
  }
}
//为什么选择我们
.choose-main{
  padding: 25px 15px;
  .index-table-title {
    padding:0px 0px;
    img{
      width: 112px;
      margin-left: 116.5px;
      height: 16px;
    }
  }
  .main-content-text{
    margin-top: 8px;
    font-size: 15px;
    line-height: 26px;
    padding: 16px 0;
    width: 100%;
    color: #414141;
    p{
      text-align: left;
    }
  }
}
.about-natural-img{
  margin: 45px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .about-natural-img-item{
    width: 98px;
    height: 143px;
  }
  .centerImg{
    width: 122px;
    height: 162px;
  }
}
.index-tx-purchase-process {
  width: 100%;
  //height: 283px;
  padding: 35px 15px;
  background: #141f32;
  .index-txpp-title {
    width: 112px;
    height: 16px;
    color: #FFFFFF;
    margin-left: 116.5px;
  }
  .index-txpp-content {
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    .index-txpp-content-left {
      width: 53px;
      height: 48px;
    }
    .index-txpp-content-right {
      margin: 0 13px;
      font-size: 13px;
      width: 280px;
      p {
        color: #D7DFFF;
        margin-bottom: 8px;
        width: 100%;
        line-height: 20px;
        text-align: center;
      }
      button {
        width: 238px;
        margin-left: 15px;
        height: 40px;
        background: linear-gradient(90deg, #b07a34, #cda84d);
        color: #ffffff;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 24px;
        }
      }

    }
  }
}
//腾讯云
.tx-purchase-process{
  width: calc(100% - 30px);
  margin: 33px 15px 25px;
  background: #ffffff;
  .index-txpr-title {
    background: #2645C0;
    img{
      width: 112px;
      margin-left: 116.5px;
      height: 16px;
    }
  }
  .index-table-table{
    width: 100%;
    font-size: 13px;
    margin-top: 35px;
    background: #FAFAFA;
    .index-table-header{
      width: 100%;
      padding: 0 15px;
      height: 40px;
      line-height: 40px;
      display: flex;
      text-align: center;
      .index-header-th{
        flex: 1;
        color: #ffffff;
        text-align: center;
      }
    }
    .index-table-content{
      //padding: 0 15px;
      margin: 0px 10px;
      display: flex;
      text-align: center;
      border-bottom: 1px solid #EAECF4;
      .index-header-th{
        flex: 1;
        color: #3F3F3F;
        text-align: center;
        p{
          line-height: 22px;
        }
      }
    }
    .index-table-content:last-child{
      border-bottom:none;
    }
  }
  .index-txpr-table{
    width: 100%;
    .index-txpr-header{
      background: #2645C0;
    }
    .index-txzb-header{
      background: #DE8436;
    }
    .index-txdb-header{
      background: #319F62;
    }
    .index-txsql-header{
      background: #39ACD8;
    }
    .content-tips{
      color: #39ACD8;
    }
    .index-txpr-content{
      height: 75px;
      padding-top: 12px;
      .index-header-th{

        p{
          line-height: 18px;
        }
      }
    }
    .index-txz-content{
      align-items: center;
      .index-header-th{
        padding-top: 12px;
        padding-bottom: 12px;
        p{
          line-height: 22px;
        }
      }
      //.index-header-th:nth-child(1){
      //  text-align: left;
      //  text-indent: 12px;
      //}
    }
    .box-position{
      position: relative;
      .service-text{
        position: absolute;
        top:40px;
        left:10px;
        color: #2C4AC2;
        font-size: 16px;
        z-index: 9;
      }
    }
  }
  .email-from{
    width: 100%;
    .from-line{
      display: flex;
      align-items: center;
      height: 40px;
      font-size: 14px;
      color: #414141;
      margin-top: 16px;
      border: 1px solid #DBDBDB;
      p{
        background: #FAFAFA;
        height: 100%;
        line-height: 40px;
        width: 85px;
        text-align: center;
      }
      input{
        text-indent: 15px;
      }
    }
  }
  .index-table-footer{
    color: #FF6609;
    font-size: 13px;
    padding: 20px 0 14px 0;
    text-align: center;
  }
  .index-table-btn{
    width: 100%;
    height: 40px;
    line-height: 40px;
    color: #ffffff;
    font-size: 16px;
  }
  .index-table-btn-txy{
    background: linear-gradient(90deg,#2645c0, #4367f4);
    box-shadow: 1px 2px 0px #1f389A;
  }
  .index-table-btn-txzb{
    background: linear-gradient(90deg,#e18e44, #f3a764);
    box-shadow: 1pt 2pt 0pt 0pt #dc8233;
  }
  .index-table-btn-txdb{
    background: linear-gradient(90deg,#2fa062, #46c880);
    box-shadow: 1pt 2pt 0pt 0pt #33a567;
  }
  .index-table-btn-txqq{
    background: linear-gradient(90deg,#26a2d0, #47bce8);
    box-shadow: 1pt 2pt 0pt 0pt #188eba;
  }
}
.index-footer{
  width: 100%;
  background: #141F32;
  padding: 22px 15px;
  .footer-news{
    font-size: 13px;
    color: #ffffff;
    margin-bottom: 22px;
    .footer-news-title{
      height: 30px;
      p:nth-child(1){
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
      }
      p:nth-child(2){
        width: 33px;
        height: 2px;
        background: #ffffff;
        margin: 13px 156px 0;
      }
    }
    .footer-news-content{
      ul{
        margin-top: 23px;
        list-style: disc;
        padding-bottom: 12px;
        border-bottom: 1px solid #192437;
        li{
          margin-bottom: 20px;
          div{
            display: flex;
            justify-content: space-between;
            .news-disc{
              width: 4px;
              height: 4px;
              border-radius: 50%;
            }
            p{
              width: 210px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            span{
              color:#565F6E;
            }
          }
        }
      }
    }
  }
  .foot-qr-code{
    width: 100%;
    height: 98px;
    text-align: center;
    .foot-qr-code-img{
      width: 74px;
      height: 74px;
      margin: 0 135px;
    }
    .foot-qr-code-text{
      margin-top: 13px;
      font-size: 11px;
      color: #ffffff;
    }
  }
}
.index-footer-company{
  font-size: 13px;
  color: #F8F8F8;
  text-align: center;
  width: 100%;
  line-height: 18px;
  padding: 15px 15px;
  background: #101B2E;
  margin-bottom: 45px;
  font-weight: 400;
}
.index-footer-btn{
  font-size: 14px;
  width: 100%;
  height: 45px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  .online{
    height: 45px;
    width: 138px;
    background: #f5f9ff;
    color: #3B63AB;
    i{
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .telephone{
    font-size: 14px;
    font-weight: bold;
    font-weight: 400;
    color: #ffffff;
    background: #3B63AB;
    width: 237px;
    height: 45px;
  }

}
.main-class-box{
  width: 100%;
  .main-class-text{
    width: 100%;
    text-align: center;
    font-size: 18px;
    letter-spacing: 3px;
    color: #414141;
  }
  .main-class-img{
    margin: 8px 124px;
    width: 97px;
    height: 4px;
  }
}
.about-top-img{
  width: 100%;
  height: 150px;
  margin-top: 50px;
}
.back-icon{
  width: 16px;
  height: 16px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  color: #000000;
  position: absolute;
  top: 35px;
  left: 30px;
  transform: rotate(180deg);
}
.clear{
  clear: both;
}
//表单
.form-content{
  width: 100%;
  padding: 32px 0 0;
  .form-item{
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .form-content-title{
      width: 60px;
      text-align: right;
      font-weight: 400;
      color: #737373;
      font-size: 14px;
    }
    .formInput{
      width: 272px;
      margin-left: 10px;
      height: 33px;
      background: #ffffff;
      text-indent:13px;
      font-size: 14px;
    }
    .formTextArea{
      height: 75px;
      padding: 5px;
      font-size: 14px;
    }
  }
  .form-submit{
    margin-left: 113px;
    .sub-btn{
      width: 178px;
      height: 40px;
      background: #0476e0;
      border-radius: 1px;
      color: #ffffff;
      font-weight: 400;
    }
  }
}
.public-function-box{
  width: 100%;
  padding: 25px 15px;
  .introduce-box{
    width: 100%;
    margin-top: 40px;
    .introduce-content{
      height: 82px;
      display: flex;
      align-items: center;
      border-top: 1px solid #F6F6F6;
      .introduce-img{
        width: 45px;
        height: 35px;
      }
      .introduce-text{
        margin-left: 13px;
        width: 287px;
        .introduce-text-title{
          font-size: 13px;
          color: #414141;
        }
        .introduce-text-content{
          margin-top: 6px;
          font-size: 11px;
          color: #737373;
          line-height: 15px;
        }
      }
    }
    .introduce-content:nth-child(1){
      border-top: none;
    }
  }
}
.public-function-box2{
  background: #FAFAFA;
  .introduce-box{
    .introduce-content{
      border-top: 1px solid #F1F1F1;
      .introduce-img{
        width: 41px;
        height: 41px;
      }
      .introduce-text{
        margin-left: 18px;
      }
    }
  }
}
